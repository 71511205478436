import SectionVideos from '../../components/sections/SectionVideos'
import CardVideos from '../../components/cards/CardVideos'
import SectionVideosOpen from '../../components/sections/SectionVideosOpen'

const Simposios = () => {
  const category = "Simposios"
  return (
    <div>
      <SectionVideosOpen
        category={category}
        title={'"La biogeografía como una evidencia de la evolución biológica"'}
        video1="https://www.youtube.com/embed/YCSpVVQwhUI?si=5OfMJTjsRuFxDVbS"
        video2="https://www.youtube.com/embed/GEV3oIYmd98?si=OhM44ihFOIZxP5Wv"
        video3="https://www.youtube.com/embed/pv170n3XV6Y?si=IObBRv8CW52LM0oi"
        video4="https://www.youtube.com/embed/fD0eaUg8v_4?si=_fGIS7JpHGh_LFLu">
        <CardVideos link={"https://www.youtube.com/embed/qrFya2B5RkQ?si=CP0HriG90FG89czB"} />
        <CardVideos link={"https://www.youtube.com/embed/fPBKKgEmVtE?si=-C7v1Ca9DtPzmlay"} />
        <CardVideos link={"https://www.youtube.com/embed/hFXnOt_SJgo?si=8-m7SAoTHVJo-Jc3"} />
        <CardVideos link={"https://www.youtube.com/embed/iWuvsW79PTY?si=CWmy7tMc137CQ6qy"} />
      </SectionVideosOpen>
      <SectionVideos
        category={category}
        title={'“Vigilancia genómica de patógenos de relevancia en salud pública”'}>
        <CardVideos link={"https://www.youtube.com/embed/7C3NxuXDAPs?si=t-S1KwD03D4xVCHO"} />
        <CardVideos link={"https://www.youtube.com/embed/ZvYYmF87mFI?si=O0zVKSNDHbAKCYMi"} />
        <CardVideos link={"https://www.youtube.com/embed/r6mBB-rwLh4?si=3PmZYmcOx_ZK5pWu"} />
      </SectionVideos>
      <SectionVideos
        category={category}
        title={'"I Simposio de helmintología neotropical:Enfoque multidisciplinario para el estudio de la diversidad de parásitos"'}
        video1="https://www.youtube.com/embed/1A8xC-ngtXA?si=Ucr9vrjLITyA0TR8"
        video2="https://www.youtube.com/embed/5OH4UpPYVqU?si=CzmCOo0TjarzefOO"
        video3="https://www.youtube.com/embed/VnyYB3stxwQ?si=X9Ln3b1BsAvsfldx"
        video4="https://www.youtube.com/embed/OwCnJg4J8yI?si=B0EcDjPJy8mS2MmE">
        <CardVideos link={"https://www.youtube.com/embed/6Pb0cdkjOvw?si=jhfXPL-6tcBBk25o"} />
        <CardVideos link={"https://www.youtube.com/embed/5q_KrTTNI0k?si=pCjcwdZ6HuSSZBwc"} />
        <CardVideos link={"https://www.youtube.com/embed/dcms0Wa24bk?si=SqlUfBTQikMnln99"} />
        <CardVideos link={"https://www.youtube.com/embed/iMwBBXNyLuo?si=HiA_DmTTsGhQ_Ugj"} />
      </SectionVideos>
    </div>
  )
}

export default Simposios
