import React from 'react';
import { motion } from 'framer-motion';

const SectionVideos = ({ category, title, children }) => {
   return (
      <motion.section
         initial={{ opacity: 0, x: 0 }}
         animate={{ opacity: 1, x: 0 }}
         exit={{ opacity: 0, x: 100 }}
         transition={{ duration: 1 }}
         className='grid gap-[24px] mt-[40px]'>
         <div>
            <h5>{category}</h5>
            <p className="p5">
               {title}
            </p>
         </div>
         <div
            className='grid gap-y-[20px]
            lg:grid-cols-2 lg:gap-[48px]'>
            {children}
         </div>
      </motion.section>
   )
}

export default SectionVideos
