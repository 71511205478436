import React, { useState } from 'react';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import CardVideos from '../cards/CardVideos';
import { useMediaQuery } from 'react-responsive';

const SectionVideosOpen = ({ category, title, children, video1, video2, video3, video4 }) => {
   const isLg = useMediaQuery({ query: '(min-width: 1024px)' })
   const [open, setOpen] = useState(false);
   const [rotate, setRotate] = useState(0);

   const arrowControls = useAnimation();

   const handleArrowClick = () => {
      setOpen(!open);
      setRotate(rotate + 180);
      // Ajusta la duración y otras propiedades según tus preferencias
      arrowControls.start({ rotate: rotate + 180, transition: { duration: 0.5 } });
   };

   return (
      <AnimatePresence wait>
         <motion.section
            initial={{ opacity: 0, x: 0 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ duration: 1 }}
            className='grid gap-[24px] mt-[40px]'>
            <div>
               <h5>{category}</h5>
               <p className="p5">
                  {title}
               </p>
            </div>
            <div className='grid gap-[24px] lg:gap-[20px]'>
               <div className='grid gap-y-[20px] lg:gap-[48px]'>
                  <div
                     className='grid gap-y-[20px]
                     lg:grid-cols-2 lg:gap-[48px]'>
                     <CardVideos link={video1} />
                     <CardVideos link={video2} />
                     <CardVideos link={video3} />
                     {isLg && <CardVideos link={video4} />}
                  </div>
                  {open && (
                     <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 20 }}
                        transition={{ duration: 0.5, delay: 0.2 }} // Ajusta la duración y el retraso según tus preferencias
                        className='grid gap-y-[20px] lg:grid-cols-2 lg:gap-[48px]'>
                        {!isLg && <CardVideos link={video4} />}
                        {React.Children.map(children, (child, index) => (
                           <motion.div
                              key={index}
                              initial={{ opacity: 0, y: 20 }}
                              animate={{ opacity: 1, y: 0 }}
                              transition={{ duration: 0.5, delay: 0.1 * index }} // Ajusta el retraso para cada video
                           >
                              {React.cloneElement(child, { key: index })}
                           </motion.div>
                        ))}
                     </motion.div>
                  )}
               </div>
               <div className='relative !h-full flex items-center gap-[16px]'>
                  <motion.img
                     src='svg/arrow.svg'
                     onClick={handleArrowClick}
                     animate={arrowControls}
                     initial={{ rotate: 0 }}
                     className='w-[32px] sm:w-[48px] lg:w-[54px] xl:w-[60px]'
                  />
                  <div className='w-full h-[1px] bg-[#445A8F]' />
               </div>
            </div>
         </motion.section>
      </AnimatePresence>
   )
}

export default SectionVideosOpen;
