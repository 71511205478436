const Splash = () => {
    return (
        <div
            className='flex justify-center items-center h-[100vh] bg-white z-20'>
            <img alt="ICBAR"
                src="/svg/logoNavICBAR.svg"
                className="w-[80%] sm:w-auto max-w-[342px]"/>
        </div>
    )
}

export default Splash