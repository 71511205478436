import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from "swiper";
import 'swiper/css/navigation';
import { Link, NavLink } from 'react-router-dom';
import { BtnVer } from '../buttons/BtnVer';
import { useMediaQuery } from 'react-responsive';

export default function HeroSwiper() {
  const isSm = useMediaQuery({ query: '(min-width: 840px)' })
  return (
    <>
      <Swiper
        navigation={true}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Navigation]}
        className="mySwiper">
        <SwiperSlide>
          <section className={`relative !bg-right bgNewHero ${isSm ? 'bg-[url("/public/home/hero/slide1.jpg")]' : 'bg-[url("/public/home/hero/slide1-mobile.jpg")]'}`}>
            <div className='containerSlideHero'>
              <h1 className='relative z-20'>
                II CONGRESO NACIONAL {" "}
                {isSm && (<br />)}
                Sobre Ciencias de la Vida
              </h1>
              <p className='p1'>
                XXIX REUNIÓN CIENTÍFICA DEL ICBAR
              </p>
              <Link to="videoteca"
                className='relative !z-30'>
                <BtnVer text={"Ver videoteca"} />
              </Link>
            </div>
          </section>
        </SwiperSlide>
        <SwiperSlide>

          <section className={`relative !bg-right bgNewHero ${isSm ? 'bg-[url("/public/home/hero/slide2.jpg")]' : 'bg-[url("/public/home/hero/slide2-mobile.jpg")]'}`}>
            <div className='containerSlideHero'>
              <h1 className='relative !z-30'>
                <span className='text-yellowi'> AHORA DISPONIBLE </span>
                <br />
                LIBRO DE RESÚMENES
              </h1>
              <p className='p1'>
                descubre la esencia de nuestro conocimiento e innovación
              </p>
              <NavLink to="libro" className='relative z-20'>
                <BtnVer text={"Descargar"} />
              </NavLink>
            </div>
          </section>
        </SwiperSlide>
      </Swiper>
      <style jsx global>{`
        @media (max-width: 800px) {
          .mySwiper .swiper-button-prev,
          .mySwiper .swiper-button-next {
            display: none;
          }
        }
      `}</style>
    </>
  );
}

