import React from 'react'

const BtnDescargar = () => {
    return (
        <a href="actividades/LIBRO_DE_RESUMENES_2023.pdf"
            download="LIBRO_DE_RESUMENES_2023.pdf"
            className='fomt-[Inter] font-bold text-center text-white rounded-[8px]
            text-[18px]  py-[12px] px-[112px]
            sm:text-[24px] sm:py-[16px]
        bg-[#5BC065] !w-full max-w-[472px] mx-auto'>
            Descarga
        </a>
    )
}

export default BtnDescargar
