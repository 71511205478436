import { motion } from "framer-motion"
import GaleryTab from '../galeria/GaleryTab'
import { BtnBack } from "../components/buttons/BtnBack"

const Galery = () => {
    return (
        <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className='pySections max-w-[1220px] mx-auto 
            px-[4%] sm:px-[6%] xl:px-0'>
            <h2 className="font-[Jost] font-bold text-[#484848]
                pb-[12px] text-[24px]
                sm:pb-[32px] aux2Sm:text-[32px] lg:text-[48px]">
                Fotos del evento
            </h2>
            <GaleryTab />
            <div className='my-[20px] sm:my-[40px] grid justify-end'>
                <BtnBack />
            </div>
        </motion.section>
    )
}

export default Galery
