import React from 'react'

const NormalImage = ({img}) => {
    return (
        <div
        className='h-[120px] sm:h-[220px] md:h-[304px] lg:h-[260px]'>
        <img alt='foto'
            src={img}
            className='w-full h-full object-cover' />
    </div>
    )
}

export default NormalImage