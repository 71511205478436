import React from 'react'

const BigImage = ({img}) => {
    return (
        <div
            className='h-[120px] sm:h-full
            lg:row-span-2 lg:col-span-2'>
            <img alt='foto'
                src={img}
                className='w-full h-full object-cover' />
        </div>
    )
}

export default BigImage