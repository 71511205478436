import React from 'react'
import CardVideos from '../../components/cards/CardVideos'
import SectionVideosOpen from '../../components/sections/SectionVideosOpen'

const Conferencias = () => {
  const category = "Conferencia:"
  return (
    <div>
      <SectionVideosOpen
        category={category}
        title={'"Diversidad genética en la medicina de la precisión"'}
        video1="https://www.youtube.com/embed/_UkWcuRqx8Y?list=PLF2wTKoArikPoRBgtN8Ox0HsddErogw3z"
        video2="https://www.youtube.com/embed/ZGIgxVfQ0DA?list=PLF2wTKoArikPoRBgtN8Ox0HsddErogw3z"
        video3="https://www.youtube.com/embed/kluSM7BDqDQ?list=PLF2wTKoArikPoRBgtN8Ox0HsddErogw3z"
        video4="https://www.youtube.com/embed/lFp0CJbF7us?list=PLF2wTKoArikPoRBgtN8Ox0HsddErogw3z">
        <CardVideos link={"https://www.youtube.com/embed/-GTAcdFqHcs?list=PLF2wTKoArikPoRBgtN8Ox0HsddErogw3z"} />
      </SectionVideosOpen>
      <SectionVideosOpen
        category={category}
        title={'"Estado actual de las biotecnologias en equinos"'}
        video1="https://www.youtube.com/embed/LvFU0FO7-zw"
        video2="https://www.youtube.com/embed/0RocT-Kl2uQ"
        video3="https://www.youtube.com/embed/9yQ65jpmphU"
        video4="https://www.youtube.com/embed/VP9DmYLABkc">
        <CardVideos link={"https://www.youtube.com/embed/eZLIqtN_no8?list=PLF2wTKoArikPoRBgtN8Ox0HsddErogw3z"} />
        <CardVideos link={"https://www.youtube.com/embed/1LkHDbv9GFU?list=PLF2wTKoArikPoRBgtN8Ox0HsddErogw3z"} />
      </SectionVideosOpen>
      <SectionVideosOpen
        category={category}
        title={'"Paradigmas en genética molecular humana"'}
        video1="https://www.youtube.com/embed/WClm2pytRyY"
        video2="https://www.youtube.com/embed/MYQn3Hod8TI"
        video3="https://www.youtube.com/embed/99okPNX0ZL0"
        video4="https://www.youtube.com/embed/oxmw7YAwG-E">
        <CardVideos link={"https://www.youtube.com/embed/MedU1GDh1AQ"} />
        <CardVideos link={"https://www.youtube.com/embed/qKTwRAq9dCA"} />
        <CardVideos link={"https://www.youtube.com/embed/4ARlLT0a7FM"} />
      </SectionVideosOpen>
    </div>
  )
}

export default Conferencias
