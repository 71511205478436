import React from 'react'
import SectionVideosOpen from '../../components/sections/SectionVideosOpen'
import CardVideos from '../../components/cards/CardVideos'

const Exposiciones = () => {
  const category = "Conferencia:"
  return (
    <div>
      <SectionVideosOpen
        category={category}
        title={'"Auditorio"'}
        video1="https://www.youtube.com/embed/2jNy_CpSaZI"
        video2="https://www.youtube.com/embed/0ehS8Kb12Rk"
        video3="https://www.youtube.com/embed/XYofJ8yv9H0"
        video4="https://www.youtube.com/embed/faCA2yDHcQI">
        <CardVideos link={"https://www.youtube.com/embed/FCrKMJIWxUM"} />
        <CardVideos link={"https://www.youtube.com/embed/XhbXealt6wI"} />
        <CardVideos link={"https://www.youtube.com/embed/LNo-yiGwYJM"} />
        <CardVideos link={"https://www.youtube.com/embed/FLfi3C5K65c"} />
        <CardVideos link={"https://www.youtube.com/embed/Cv3QKpMZqok"} />
      </SectionVideosOpen>
      <SectionVideosOpen
        category={category}
        title={'"Sala 02"'}
        video1="https://www.youtube.com/embed/6mU5fvUYhlo"
        video2="https://www.youtube.com/embed/nKMTdrl-7-g"
        video3="https://www.youtube.com/embed/dR2WyqPjrs8"
        video4="https://www.youtube.com/embed/ME7i8m10E84">
        <CardVideos link={"https://www.youtube.com/embed/qGvBAyki4TQ"} />
        <CardVideos link={"https://www.youtube.com/embed/Bu1dTIXs4DI"} />
      </SectionVideosOpen>
      <SectionVideosOpen
        category={category}
        title={'"Sala 01"'}
        video1="https://www.youtube.com/embed/uUBRVBKLj68"
        video2="https://www.youtube.com/embed/uTuYnKUWOY8"
        video3="https://www.youtube.com/embed/pyWiHX83XXo"
        video4="https://www.youtube.com/embed/gflOM1fgnMY">
        <CardVideos link={"https://www.youtube.com/embed/6luWw0HmXl8"} />
        <CardVideos link={"https://www.youtube.com/embed/e-2XtvcBa7A"} />
        <CardVideos link={"https://www.youtube.com/embed/f-45KYhaqyE"} />
        <CardVideos link={"https://www.youtube.com/embed/3Rm0QIjqpa0"} />
        <CardVideos link={"https://www.youtube.com/embed/tSMsr1ngLZ8"} />
      </SectionVideosOpen>
    </div>
  )
}

export default Exposiciones
