import * as React from 'react';
import { styled } from '@mui/system';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Day1 from './Day1';
import Day2 from './Day2';
import Day3 from './Day3';

export default function GaleryTab() {
  return (
    <Tabs defaultValue={1} selectionFollowsFocus>
      <StyledTabsList centered>
        <StyledTab value={1}><p className='tabGalery'>Día 1</p></StyledTab>
        <StyledTab value={2}><p className='tabGalery'>Día 2</p></StyledTab>
        <StyledTab value={3}><p className='tabGalery'>Día 3</p></StyledTab>
      </StyledTabsList>
      <StyledTabPanel value={1} className='py-[32px] sm:py-[40px]'>
        <Day1 />
      </StyledTabPanel>
      <StyledTabPanel value={2} className='py-[32px] sm:py-[40px]'>
        <Day2 />
      </StyledTabPanel>
      <StyledTabPanel value={3} className='py-[32px] sm:py-[40px]'>
        <Day3 />
      </StyledTabPanel>
    </Tabs>
  );
}

const StyledTab = styled(Tab)`
  font-family: 'Inter';
  font-weight: 700;
  color: #000000;
  cursor: pointer;
  width: 100%;
  padding: 8px 12px;
  margin: 6px;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  border: 3px solid #EBC235;
  &:hover {
    background-color: #F1DFA1;
  }

  &:focus {
    background-color: #EBC235;
  }

  &.${tabClasses.selected} {
    border: 3px solid #EBC235;
    background-color: #EBC235;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
`;

const StyledTabsList = styled(TabsList)(
  ({ theme }) => `
  width: 100%;
  max-width: 690px !important;
  margin: 0 auto;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  gap: 10px;
    };
  `,
);