import { BtnBack } from '../components/buttons/BtnBack'
import DaysTab from '../videoteca/DaysTab'
import { motion } from "framer-motion"

const Videoteca = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}>
      <section id="Resumenes"
        className='max-w-[1312px] min-h-[80vh]
        mx-auto px-[4%] 2xl:px-0 pySections'>
        <DaysTab />
        <div className='my-[20px] sm:my-[40px] grid justify-end'>
          <BtnBack />
        </div>
      </section>
    </motion.div>
  )
}

export default Videoteca
