import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Day1Tab from './day1/Day1Tab';
import Day2Tab from './day2/Day2Tab';
import Day3Tab from './day3/Day3Tab';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function DaysTab() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="none"
                    aria-label="basic tabs example" centered>
                    <Tab
                        label="Día 1"
                        {...a11yProps(0)}
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: ['24px', '32px'],
                            fontWeight: 'bold',
                            color: '#1E2A4B',
                            textTransform: 'none',
                        }}
                    />
                    <Tab
                        label="Día 2"
                        {...a11yProps(1)}
                        sx={{
                            fontFamily: 'Poppins',
                            fontSize: ['24px', '32px'],
                            fontWeight: 'bold',
                            color: '#1E2A4B',
                            textTransform: 'none',
                        }}
                    />
                    <Tab
                        label="Día 3"
                        {...a11yProps(2)}
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: ['24px', '32px'],
                            fontWeight: 'bold',
                            color: '#1E2A4B',
                            textTransform: 'none',
                        }}
                    />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <Day1Tab />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Day2Tab />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <Day3Tab />
            </CustomTabPanel>
        </Box>
    );
}
