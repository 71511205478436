import React from 'react'

export const CardTitleSimposios = ({ text, children, bgColor, textColor, gradientColors}) => {
   const gradientStyle = `linear-gradient(to bottom, ${gradientColors[0]}, ${gradientColors[1]})`;
   return (
      <div className='lg:h-full  xl:max-h-[900px]'>
         <div
            className='bg-yellowi px-[40px] w-full h-[54px]
            flex items-center justify-center mb-[20px]
            text-azuli text-center uppercase'>
            <p className='font-[Poppins] font-bold 
            text-center text-[32px]'>
               {text}
            </p>
         </div>
         <ul
            style={{ backgroundImage: gradientStyle }}
            className={`bg-${bgColor} text-${textColor} font-[Mulish] space-y-[12px]
            text-[24px] py-[18px] px-[10px] pl-[40px] list-disc h-full leading-[30px]
            sm:text-[25px] sm:py-[32px] sm:px-[10px] sm:pl-[40px]`}>
            {children}
         </ul>
      </div>
   )
}
