import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { motion } from "framer-motion"
import { Squash as Hamburger } from 'hamburger-react'
import Footer from './Footer'
import { NavLink } from 'react-router-dom'

const NavBar = () => {
   const isSm = useMediaQuery({ query: '(min-width: 640px)' })
   const isXl = useMediaQuery({ query: '(min-width: 1000px)' })
   const [isOpen, setOpen] = useState(false)
   return (
      <header
         className='w-full flex items-center justify-center h-[64px] sm:h-[84px] relative shadow-md'>
         <nav
            className='flex items-center justify-between
            max-w-[1178px] w-full mx-auto px-[4%] sm:px-[6%] xl:px-0'>
            <NavLink to="/">
               <img alt='ICBAR' src='/svg/logoNavICBAR.svg'
                  className='cursor-pointer w-[173px] sm:w-[306px]' />
            </NavLink>
            {isXl ?
               <div
                  className='flex items-center gap-[40px] xl:gap-[64px]'>
                  <NavLink to="/">
                     <p className="pNav squiggle">Inicio</p>
                  </NavLink>
                  <NavLink to="libro">
                     <p className="pNav squiggle">Libro</p>
                  </NavLink>
                  <NavLink to="videoteca">
                     <p className="pNav squiggle">Videoteca</p>
                  </NavLink>
                  <NavLink to="galeria">
                     <p className="pNav squiggle">Fotos del evento</p>
                  </NavLink>
               </div>
               :
               <div>
                  {isSm ?
                     <Hamburger color="#1E2A4B" size={48}
                        toggled={isOpen} toggle={setOpen} />
                     :
                     <Hamburger color="#1E2A4B" size={24}
                        toggled={isOpen} toggle={setOpen} />
                  }
               </div>
            }
         </nav>
         {isOpen && !isXl &&
            <motion.div
               initial={{ opacity: 0, x: 100 }}
               animate={{ opacity: 1, x: 0 }}
               exit={{ opacity: 0, x: 100 }}
               transition={{ duration: 1 }}
               className='absolute top-[64px] sm:top-[84px] right-0 bg-[#131C37]
               w-full sm:w-[360px] !z-40'>
               <NavLink to="/"
                  className="menuOptions"
                  onClick={() => setOpen(!isOpen)}>
                  <p className="pNav">Inicio</p>
               </NavLink>
               <NavLink to="libro"
                  className="menuOptions"
                  onClick={() => setOpen(!isOpen)}>
                  <p className="pNav">Libro</p>
               </NavLink>
               <NavLink to="videoteca"
                  className="menuOptions"
                  onClick={() => setOpen(!isOpen)}>
                  <p className="pNav">Videoteca</p>
               </NavLink>
               <NavLink to="galeria"
                  className="menuOptions"
                  onClick={() => setOpen(!isOpen)}>
                  <p className="pNav">Fotos del evento</p>
               </NavLink>
               <Footer />
            </motion.div>
         }
      </header>
   )
}

export default NavBar
