import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";
import CardConferencias from "../cards/CardConferencias";
import CardConferencias2 from "../cards/CardConferencias2";
export default function SwiperConferencias() {
  return (
    <>
      <Swiper
        style={{
          "--swiper-pagination-color": "#969696",
          "--swiper-pagination-bullet-inactive-color": "#D9D9D9",
          "--swiper-pagination-bullet-inactive-opacity": "1",
        }}
        slidesPerView={3}
        spaceBetween={32}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          1072: {
            slidesPerView: 3.4,
            slidesPerGroup: 3,
            spaceBetween: 24,
          },
          980: {
            slidesPerView: 3.2,
            slidesPerGroup: 1,
            spaceBetween: 24,
          },
          760: {
            slidesPerView: 2.6,
            slidesPerGroup: 1,
            spaceBetween: 24,
          },
          680: {
            slidesPerView: 2.2,
            slidesPerGroup: 1,
            spaceBetween: 16,
          },
          488: {
            slidesPerView: 1.8,
            slidesPerGroup: 1,
            spaceBetween: 16,
          },
          360: {
            slidesPerView: 1.35,
            slidesPerGroup: 1,
            spaceBetween: 16,
          },
          100: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 16,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <CardConferencias name="Dr. Christian Ibañez" img="/home/conferencias/drChristian.png"
            text="Biólogo Marino doctorado en Ecología y Biología Evolutiva en la Universidad de Chile. Es profesor titular de la Facultad de Ciencias de la Vida de la Universidad Andrés Bello. Sus investigaciones se centran en la biodiversidad, ecología y evolución de invertebrados marinos en aspectos biogeográficos y macroevolutivos." />
        </SwiperSlide>
        <SwiperSlide>
          <CardConferencias name="Dr. Marcelo Oliva" img="/home/conferencias/drMarcelo.png"
            text="Biólogo pesquero doctorado en Oceanología Biológica en la Universidad de Bretaña Occidental (Francia). Es profesor titular de la Facultad de Ciencias del Mar y Recursos Biológicos de la Universidad de Antofagasta. Su área de investigación es la parasitología marina enfocada al estudio de sistemas parásito-huésped de peces e invertebrados, en aspectos ecológicos y pesqueros." />
        </SwiperSlide>
        <SwiperSlide>
          <CardConferencias name="Dr. Heinner Guio" img="/home/conferencias/drHeinner.png"
            text="Médico doctorado en Ciencias Médicas en la universidad de Tohoku, Japón y realizó su postdoctorado en el Imperial College de Londres, Inglaterra. Sus principales investigaciones se centran en la identificación de nuevas variantes genéticas en poblaciones nativas y mestizas peruanas y búsqueda de marcadores genéticos e inmunológicos en la infección por tuberculosis." />
        </SwiperSlide>
        <SwiperSlide>
          <CardConferencias name="Dr. Marcelo Miragaya" img="/home/conferencias/marcelo.png"
            text="Médico Veterinario doctorado en Fisiología Animal en la University of Idaho, USA.  Es profesor titular de la cátedra de Teriogenología de la Facultad de Ciencias Veterinarias de la UBA. Su línea de investigación está aplicada a la reproducción y biotecnologías reproductivas en la especie equina y en camélidos sudamericanos" />
        </SwiperSlide>
        <SwiperSlide>
          <CardConferencias2 name="Dr. Ricardo Fujita" img="/home/conferencias/drRicardo.png"
            text="Biólogo genetista doctorado en Genética Molecular en la Universidad de Strasbourg, Francia y realizó su postdoctorado en la Universidad de Michigan, EEUU. Es Director del Instituto de Investigación de la Facultad de Medicina Humana de la Universidad San Martín de Porres. Sus líneas de investigación incluyen: Enfermedades Raras, Cáncer," 
            text2=" Farmacogenética, Inmunogenética, la Historia vista por la Genética, Terapia Génica, Células Madre."/>
        </SwiperSlide>
        <SwiperSlide>
          <CardConferencias2 name="Dra. Mev Domínguez" img="/home/conferencias/drMev.png"
            text="Bióloga genetista doctorada en Oncología en la Universidad Federal de Sao Paulo, Brasil. Es investigadora del Departamento de Biología Tumoral del Cáncer del Instituto de Investigación en Cáncer del Hospital Universitario de Oslo, Noruega, directora del programa  del Grupo Europeo de Tumores Hereditarios y asesora científica del Grupo Latinoamericano de Tumores Hereditarios." 
            text2=" Su línea de investigación es en Síndrome de Lynch."/>
        </SwiperSlide>
        <SwiperSlide>
          <CardConferencias2 name="Dr. Jaeson Calla" img="/home/conferencias/drJaeson.png"
            text="Biólogo doctorado en Centro de Investigación y Estudios Avanzados del Instituto Nacional Politécnico, México. Es científico asistente de proyectos e investigador de la malaria en el Laboratorio de Patogénesis Eucariótica, Descubrimiento de Fármacos y Biología Química de la Facultad de Medicina de la Universidad de California en San Diego. Trabaja en el descubrimiento "
            text2="de compuestos antipalúdicos y la respuesta inmunitaria por Plasmodium durante las infecciones exoeritrocitarias." />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
