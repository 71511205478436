import React from 'react'
import SectionVideos from '../../components/sections/SectionVideos'
import CardVideos from '../../components/cards/CardVideos'

const Conferencias = () => {
  const category = "Conferencia:"
  return (
    <div>
      <SectionVideos
        category={category}
        title={'"La biogeografía como una evidencia de la evolución biológica"'}>
        <CardVideos link={"https://www.youtube.com/embed/_8OcmsVc6TQ?si=doJWi-ya75SXDws7"} />
        <CardVideos link={"https://www.youtube.com/embed/QJnZJ5wlOhw?si=F82kvkXv3R4g4jQ4"} />
      </SectionVideos>
      <SectionVideos
        category={category}
        title={'"Apicomplexan parasites manipulate the host secretion apparatus to favor their development and to hide from the immune system"'}>
        <CardVideos link={"https://www.youtube.com/embed/je_atMDiBz8?si=V6o9drtYLTvU-aD_"} />
      </SectionVideos>
      <SectionVideos
        category={category}
        title={'"Herramientas genómicas en el mejoramiento vegetal"'}>
        <CardVideos link={"https://www.youtube.com/embed/83j3-JRFZT8?si=Rt8QHZYTjJdj120Z"} />
      </SectionVideos>
    </div>
  )
}

export default Conferencias
