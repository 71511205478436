import React from 'react'
import SectionVideos from '../../components/sections/SectionVideos'
import SectionVideosOpen from '../../components/sections/SectionVideosOpen'
import CardVideos from '../../components/cards/CardVideos'

const Conferencias = () => {
  const category = "Conferencia:"
  return (
    <div>
      <SectionVideosOpen
        category={category}
        title={'"Medicina personalizada en cáncer colorrectal hereditario"'}
        video1="https://www.youtube.com/embed/ZYyHRHJhcvA?list=PLF2wTKoArikNLiu6xnDo397Ydr0xHOztP"
        video2="https://www.youtube.com/embed/ncIrqLBB_xU?list=PLF2wTKoArikNLiu6xnDo397Ydr0xHOztP"
        video3="https://www.youtube.com/embed/TvNbvjeLN2o?list=PLF2wTKoArikNLiu6xnDo397Ydr0xHOztP"
        video4="https://www.youtube.com/embed/qStzfVxPrdY?list=PLF2wTKoArikNLiu6xnDo397Ydr0xHOztP">
        <CardVideos link={"https://www.youtube.com/embed/d4vOGmhaVbU?list=PLF2wTKoArikNLiu6xnDo397Ydr0xHOztP"} />
      </SectionVideosOpen>
      <SectionVideos
        category={category}
        title={'"Parásitos como indicadores de procesos oceanográficos el caso de sistemas de afloramiento"'}>
        <CardVideos link={"https://www.youtube.com/embed/AxISj8tV6WU?list=PLF2wTKoArikNLiu6xnDo397Ydr0xHOztP"} />
        <CardVideos link={"https://www.youtube.com/embed/TKRWbf56bC0?list=PLF2wTKoArikNLiu6xnDo397Ydr0xHOztP"} />
        <CardVideos link={"https://www.youtube.com/embed/ZRGzE6FDRzU?list=PLF2wTKoArikNLiu6xnDo397Ydr0xHOztP"} />
      </SectionVideos>
    </div>
  )
}

export default Conferencias
