import React from 'react'
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

const CardConferencias2 = ({ name, text, text2, img }) => {
    const [ver, setVer] = useState(true)
    const isXl = useMediaQuery({ query: '(min-width: 1280px)' })
    return (
        <div
            className='w-[243px] h-full sm:w-[272px] xl:w-full mx-auto bg-white
        py-[24px] sm:py-[16px] px-[16px] mb-[50px] md:mb-[72px] xl:mb-0
        border-[1px] border-[#CACACA] rounded-[8px]'>
            <img alt={name} src={img}
                className='mx-auto' />
            <p className='text-center text-azuli
                font-[Jost] font-semibold 
                text-[20px] py-[16px] sm:text-[24px] sm:pb-[8px]'>
                {name}
            </p>
            <p className='text-center font-Nsans
                text-[14px]  leading-[20px]
                sm:text-[16px] sm:leading-[24px]'>
                {text} {" "}
                {isXl ?
                    <span>{ text2 }</span>
                    :
                    <span>
                        {ver ?
                            <span className='underline font-medium cursor-pointer'
                                onClick={() => setVer(!ver)}>
                                ...ver más
                            </span>
                            :
                            <span>
                                {text2}
                                <span className='underline font-medium cursor-pointer'
                                    onClick={() => setVer(!ver)}>
                                    Ver menos
                                </span>
                            </span>
                        }

                    </span>
                }
            </p>
        </div>
    )
}

export default CardConferencias2
