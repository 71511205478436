import React from 'react'
import { BtnBack } from '../../components/buttons/BtnBack'
import { NavLink } from 'react-router-dom'
import { CardTitleSimposios } from '../../components/cards/CardTitleSimposios'
import { motion } from "framer-motion"

export const Simposios = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className='w-full mx-auto h-full
            px-[4%] lg:px-0 max-w-[1180px]'>
            <section className='py-[40px] md:py-[60px]'>
                <div
                    className='bg-yellowi px-[40px] max-w-[250px] h-[56px]
                    flex items-center justify-center mb-[20px] mx-auto
                    text-azuli text-center rounded-[16px]'>
                    <p className='font-[Poppins] font-bold 
                        text-center text-[32px]'>
                        Simposios
                    </p>
                </div>
                <div
                    className='grid gap-[32px] w-full my-[24px]
                    lg:grid-cols-3 lg:gap-[20px] lg:my-[40px]'>
                    <div className='lg:!h-full'>
                        <CardTitleSimposios text="MIÉRCOLES 09" gradientColors={['#FFFFFF', '#D3FFD6']} bgColor="" textColor="azuli">
                            <li>Taxonomía integrativa de peces neotropicales.</li>
                            <li>Vigilancia genómica de patógenos de relevancia en salud pública.</li>
                            <li>Flora endémica y amenazada en los Andes peruanos.</li>
                            <li>I Simposio de Helmintología Neotropical: Enfoque multidisciplinario para el estudio de la diversidad de parásitos.</li>
                            <li>Avances en investigación sobre humedales peruanos.</li>
                            <li>Conferencia: “Herramientas genómicas en el mejoramiento vegetal”</li>
                        </CardTitleSimposios>
                    </div>
                    <div className='lg:!h-full'>
                        <CardTitleSimposios text="JUEVES 10" gradientColors="" bgColor="azuli" textColor="white">
                            <li>La odisea diagnóstica de las enfermedades raras.</li>
                            <li>Dilemas y desafíos en el conocimiento del género Cinchona en el Perú.</li>
                            <li>Reproducción y biología del desarrollo preimplantacional del embrión. </li>
                            <li>Genómica evolutiva.</li>
                            <li>Ecología de reptiles.</li>
                            <li>Ecología de ecosistemas bentónicos.</li>
                        </CardTitleSimposios>
                    </div>
                    <div className='lg:!h-full'>
                        <CardTitleSimposios text="VIERNES 11" gradientColors={['#FFFFFF', '#D3FFD6']} bgColor="" textColor="azuli">
                            <li>Biotecnologías reproductivas aplicadas en reproducción asistida.</li>
                            <li>Inmunología del cáncer.</li>
                            <li>Series de tiempo y los beneficios del monitoreo de biodiversidad de largo plazo.</li>
                            <li>Aplicaciones de la biotecnología para la generación de anticuerpos contra el veneno animal.</li>
                            <li>Actualidad de la investigación y aplicación de bacteriófagos en el Perú.</li>
                            <li>Genética y genómica del cáncer.</li>
                            <li>Macroinvertebrados acuáticos y su importancia como bioindicadores de la calidad del agua en Perú.</li>
                            <li>Experimentos y aplicación de tecnologías para la conservación de la biodiversidad.</li>
                        </CardTitleSimposios>
                    </div>
                </div>
            </section>
            <section className='mb-[20px] sm:mb-[40px]'>
                <NavLink to="/"
                    className='grid justify-center'>
                    <BtnBack />
                </NavLink>
            </section>
        </motion.div>
    )
}
