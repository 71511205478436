import { Route, Routes } from "react-router-dom";
import Splash from "./components/animations/Splash";
import Home from "./pages/Home";
import Footer from "./shared/Footer";
import NavBar from "./shared/NavBar";
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from "react";
import Videoteca from "./pages/Videoteca";
import { Simposios } from "./pages/second/Simposios";
import { ConferenciasMagistrales } from "./pages/second/ConferenciasMagistrales";
import Galery from "./pages/Galery";
import { Libro } from "./pages/second/Libro";

function App() {
   const [splash, setSplash] = useState(true);
   useEffect(() => {
      setTimeout(() => {
         setSplash(false);
      }, 3000);
   }, []);
   return (
      <AnimatePresence>
         {
            splash ?
               (<Splash key="splash" />)
               :
               (
                  <motion.div
                     initial={{ opacity: 0 }}
                     animate={{ opacity: 1 }}
                     transition={{ duration: 1.5 }}
                     className="overflow-hidden relative min-h-[100vh]"
                  >
                     <NavBar />
                     <Routes>
                        <Route index path="/" element={<Home />} />
                        <Route path="videoteca" element={<Videoteca/>} />
                        <Route path="simposios" element={<Simposios/>} />
                        <Route path="conferencias-magistrales" element={<ConferenciasMagistrales/>} />
                        <Route path="libro" element={<Libro/>} />
                        <Route path="galeria" element={<Galery/>} />
                     </Routes>
                     <Footer />
                  </motion.div>
               )
         }
      </AnimatePresence>
   );
}

export default App;
