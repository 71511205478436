import React from 'react'
import SectionVideos from '../../components/sections/SectionVideos'
import CardVideos from '../../components/cards/CardVideos'
import SectionVideosOpen from '../../components/sections/SectionVideosOpen'

const Exposiciones = () => {
  const category = "EXPOSICIONES  ORALES:"
  return (
    <div>
      <SectionVideos
        category={category}
        title={'"Auditorio"'}>
        <CardVideos link={"https://www.youtube.com/embed/0ehS8Kb12Rk?list=PLF2wTKoArikN3jYfVGXnnAr26VuxghitV"} />
        <CardVideos link={"https://www.youtube.com/embed/2jNy_CpSaZI?list=PLF2wTKoArikN3jYfVGXnnAr26VuxghitV"} />
        <CardVideos link={"https://www.youtube.com/embed/XYofJ8yv9H0?list=PLF2wTKoArikN3jYfVGXnnAr26VuxghitV"} />
        <CardVideos link={"https://www.youtube.com/embed/faCA2yDHcQI?list=PLF2wTKoArikN3jYfVGXnnAr26VuxghitV"} />
      </SectionVideos>
      <SectionVideosOpen
        category={category}
        title={'"Sala 02"'}
        video1="https://www.youtube.com/embed/qjen2u3MQJs?list=PLF2wTKoArikN3jYfVGXnnAr26VuxghitV"
        video2="https://www.youtube.com/embed/ILNoPm33yWg?list=PLF2wTKoArikN3jYfVGXnnAr26VuxghitV"
        video3="https://www.youtube.com/embed/Z3g5Sz4cUU4?list=PLF2wTKoArikN3jYfVGXnnAr26VuxghitV"
        video4="https://www.youtube.com/embed/LJUbpZNG8yg?list=PLF2wTKoArikN3jYfVGXnnAr26VuxghitV">
        <CardVideos link={"https://www.youtube.com/embed/NY9GThgwasY?list=PLF2wTKoArikN3jYfVGXnnAr26VuxghitV"} />
      </SectionVideosOpen>
      <SectionVideosOpen
        category={category}
        title={'"Sala 01"'}
        video1="https://www.youtube.com/embed/NVDFFyFTPk8?list=PLF2wTKoArikN3jYfVGXnnAr26VuxghitV"
        video2="https://www.youtube.com/embed/Xs7UCfUITtA?list=PLF2wTKoArikN3jYfVGXnnAr26VuxghitV"
        video3="https://www.youtube.com/embed/kKzIzombIBk?list=PLF2wTKoArikN3jYfVGXnnAr26VuxghitV"
        video4="https://www.youtube.com/embed/7E3okIUo7tA?list=PLF2wTKoArikN3jYfVGXnnAr26VuxghitV">
        <CardVideos link={"https://www.youtube.com/embed/fMSu-S7-cXI?list=PLF2wTKoArikN3jYfVGXnnAr26VuxghitV"} />
        <CardVideos link={"https://www.youtube.com/embed/eCzQaV8Eic8?list=PLF2wTKoArikN3jYfVGXnnAr26VuxghitV"} />
        <CardVideos link={"https://www.youtube.com/embed/i3x9tV0WWus?list=PLF2wTKoArikN3jYfVGXnnAr26VuxghitV"} />
        <CardVideos link={"https://www.youtube.com/embed/7JvYitmNtSY?list=PLF2wTKoArikN3jYfVGXnnAr26VuxghitV"} />
      </SectionVideosOpen>
    </div>
  )
}

export default Exposiciones
