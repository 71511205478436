import React from 'react'
import SectionVideos from '../../components/sections/SectionVideos'
import CardVideos from '../../components/cards/CardVideos'
import SectionVideosOpen from '../../components/sections/SectionVideosOpen'

const Exposiciones = () => {
  const category = "EXPOSICIONES  ORALES:"
  return (
    <div>
      <SectionVideosOpen
        category={category}
        title={'"Auditorio"'}
        video1="https://www.youtube.com/embed/67MsOR2MrJE?si=7xqPVlfHXyJhZe16"
        video2="https://www.youtube.com/embed/7CuXtOo8280?si=u6TRwl5yi7iYjIKo"
        video3="https://www.youtube.com/embed/YsThSuUlPHw?si=FPWpr0RA4ihYTnim"
        video4="https://www.youtube.com/embed/7jOpuVoAHh0?si=mO5XaNtAhTZYSNcQ">
        <CardVideos link={"https://www.youtube.com/embed/VNzz0z2dPWs?si=NHbP0QhObLtHTzH2"} />
        <CardVideos link={"https://www.youtube.com/embed/DFUfTeYeTMk?si=GGmQBtw_gJhBVFyD"} />
        <CardVideos link={"https://www.youtube.com/embed/WI8jJed2TDE?si=bHCJqnjj-x82iOe2"} />
        <CardVideos link={"https://www.youtube.com/embed/NG_4ZqvvfIs?si=fF2t6rhFo6IKyAj2"} />
      </SectionVideosOpen>
      <SectionVideosOpen
        category={category}
        title={'"Sala 02"'}
        video1="https://www.youtube.com/embed/GHo-kIQPqGM?si=P20yBcK6SHJi9xV9"
        video2="https://www.youtube.com/embed/HkW9AW2h_FE?si=9Z1I_0XQjmts2lCV"
        video3="https://www.youtube.com/embed/bys9roFqArw?si=77ZEo-BXufjBcT7A"
        video4="https://www.youtube.com/embed/PQrsgA7G32c?si=NexXGe7PX9yA-bDy">
        <CardVideos link={"https://www.youtube.com/embed/Rv9nqO_JTeU?si=E-lFFfl13jmehdXM"} />
        <CardVideos link={"https://www.youtube.com/embed/dc7ucXL9v4k?si=FVukogLizOxb8MFP"} />
      </SectionVideosOpen>
      <SectionVideos
        category={category}
        title={'"Sala 01"'}>
        <CardVideos link={"https://www.youtube.com/embed/mwi700wbTY4?si=2sMsWpaHIbqt44FO"} />
        <CardVideos link={"https://www.youtube.com/embed/7bVh6TMxCOE?si=yzcT77f7KO4MjBgz"} />
        <CardVideos link={"https://www.youtube.com/embed/iSSQBpMJ_nY?si=XCPArv6X7MvfFDnE"} />
        <CardVideos link={"https://www.youtube.com/embed/iSSQBpMJ_nY?si=wEd-6s_UYZCFrYPT"} />
      </SectionVideos>
    </div>
  )
}

export default Exposiciones
