import React from 'react'

const BtnConferencias = () => {
  return (
    <button
        className='bg-yellowi rounded-[8px] border-[2px] border-yellowi
        flex items-center justify-center  text-black
        w-full h-[52px] hover:bg-transparent hover:text-yellowi'>
        <p className='font-[Inter] font-bold text-[18px] sm:text-[20px] '>
          Conferencias magistrales
        </p>
    </button>
  )
}

export default BtnConferencias