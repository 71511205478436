import React from 'react'
import { Link } from 'react-router-dom'
import { BtnBack } from '../../components/buttons/BtnBack'
import { motion } from "framer-motion"
import BtnDescargar from '../../components/buttons/BtnDescargar'
import { useMediaQuery } from 'react-responsive'

export const Libro = () => {
    const isSm = useMediaQuery({ query: '(min-width: 640px)' })
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className='min-h-[86.5vh]'>
            <section id="Resumenes"
                className="bg-[url('/public/home/hero/libro-bg.jpg')] bg-cover bg-left">
                <div className='w-[92%] mx-auto max-w-[760px] py-[32px] sm:py-[56px]'>
                    <h6>
                        ya puedes descargar el libro del
                        <br />
                        <span className='text-[#EBC235]'>
                            “II congreso nacional sobre ciencias de la vida, xxix reunión científica del icbar“
                        </span>
                    </h6>
                    {isSm &&
                        <div className='flex justify-center w-full pt-[20px]'>
                            <BtnDescargar />
                        </div>
                    }
                </div>
            </section>
            <section id="Resumenes"
                className='max-w-[1200px]  mx-auto px-[4%] xl:px-0 py-[20px] sm:py-[40px]'>
                {
                    isSm ?
                        <iframe src="actividades/LIBRO_DE_RESUMENES_2023.pdf"
                            title="LIBRO DE RESÚMENES 2023"
                            className='mt-[20px] sm:mt-[32px] border-0 
                            w-[100%] h-[0px] sm:h-[1000px]' />
                        :
                        <div className='mb-[40px]'>
                            <img src='/actividades/libro-icbar.png' alt=''
                                className='w-full max-w-[330px] mx-auto' />
                            <div className='flex justify-center w-full'>
                                <BtnDescargar />
                            </div>
                        </div>
                }
                <div className='my-[20px] sm:my-[40px]'>
                    <Link to="/"
                        className='grid justify-center'>
                        <BtnBack />
                    </Link>
                </div>
            </section>
        </motion.div>
    )
}
