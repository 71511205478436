import React from 'react'
import { Link } from 'react-router-dom'

export const BtnBack = () => {
    return (
        <Link to="/">
            <button
                className='border-[2px] border-[#131C37] rounded-[8px] py-[8px] sm:py-0
            px-[24px] w-auto h-[36px] sm:h-[40px] text-[#131C37] relative
		    hover:bg-[#131C37] hover:text-white'>
                <p
                    className='font-[Jost] font-bold 
            text-[12px]	sm:text-[16px] text-center'>
                    ← Volver al inicio
                </p>
            </button>
        </Link>
    )
}
