import * as React from 'react';
import { styled } from '@mui/system';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import TabPanel from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import Tab, { tabClasses } from '@mui/base/Tab';
import Simposios from './Simposios';
import Conferencias from './Conferencias';
import Exposiciones from './Exposiciones';

export default function Day3Tab() {
  return (
    <Tabs defaultValue={1} selectionFollowsFocus >
      <StyledTabsList centered>
        <StyledTab value={1}><p className='tabTextDay'>Simposios</p></StyledTab>
        <StyledTab value={2}><p className='tabTextDay'>Conferencias</p></StyledTab>
        <StyledTab value={3}><p className='tabTextDay'>Exposiciones </p></StyledTab>
      </StyledTabsList>
      <StyledTabPanel value={1}>
        <Simposios />
      </StyledTabPanel>
      <StyledTabPanel value={2}>
        <Conferencias />
      </StyledTabPanel>
      <StyledTabPanel value={3}>
        <Exposiciones />
      </StyledTabPanel>
    </Tabs>
  );
}

const blue = {
  50: '#445A8F',
  100: '#364262',
  200: '#364262',
  300: '#66B2FF',
  400: '#FFF',
  500: '#FFF',
  600: '#1E2A4B',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

const StyledTab = styled(Tab)`
  font-family: 'IBM Plex Sans', sans-serif;
  color: #1E2A4B;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  line-height: 1.5;
  padding: 8px 12px;
  margin: 6px;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: rgba(68, 90, 143, 0.2 );
  }

  &:focus {
    color: #1E2A4B;
  }

  &.${tabClasses.selected} {
    background-color: #445A8F;
    color: white;
    outline: 3px solid white;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
`;

const StyledTabsList = styled(TabsList)(
  ({ theme }) => `
  width: 100%;
  max-width: 467px !important;
  margin: 0 auto;
  background-color: ${blue[500]};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 4px 6px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.3)' : 'rgba(0,0,0, 0.3)'
    };
  `,
);