import React from 'react'

const BtnSimposios = () => {
  return (
    <button
        className='bg-azuli rounded-[8px] border-[2px] border-azuli
        flex items-center justify-center text-white
        w-full h-[52px] hover:bg-transparent hover:text-azuli' >
        <p className='font-[Inter] font-bold text-[18px] sm:text-[20px] '>
            Simposios
        </p>
    </button>
  )
}

export default BtnSimposios