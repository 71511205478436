import React from 'react'

export const CardConferencistas = ({img, name, text, nameColor, textColor}) => {
  return (
    <div className='text-center'>
        <img alt={name}
            src={img}
            className='rounded-full mx-auto object-cover
            w-[151px] h-[151px]'>
        </img>
        <p className={`font-Nsans font-semibold text-${nameColor}
            text-[22px] sm:text-[24px] text-center pt-[8px]`}>
            {name}
        </p>
        <p className={`font-Nsans text-${textColor}
            text-[16px] sm:text-[22px] text-center`} >
            {text}
        </p>
    </div>
  )
}
