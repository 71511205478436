import React from 'react'
import { BtnBack } from '../../components/buttons/BtnBack'
import { NavLink } from 'react-router-dom'
import { CardTitleSimposios } from '../../components/cards/CardTitleSimposios'
import { CardConferencistas } from '../../components/cards/CardConferencistas'
import { motion } from "framer-motion"

export const ConferenciasMagistrales = () => {
   return (
      <motion.div
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{ duration: 0.8 }}
         className='w-full mx-auto h-full
            px-[4%] lg:px-0 max-w-[1180px]'>
         <section className='py-[40px] md:py-[60px]'>
            <div
               className='bg-yellowi px-[40px] max-w-[564px] sm:h-[56px]
               flex items-center justify-center mb-[20px] mx-auto
               text-azuli text-center rounded-[16px]'>
               <p className='font-[Poppins] font-bold 
                  text-center text-[32px]'>
                  CONFERENCIAS MAGISTRALES
               </p>
            </div>
            <div
               className='grid gap-[32px] w-full my-[24px]
               lg:grid-cols-3 lg:gap-[20px] lg:my-[40px]'>
               <div className='lg:!h-full'>
                  <CardTitleSimposios text="MIÉRCOLES 09" gradientColors={['#FFFFFF', '#D3FFD6']} bgColor="" textColor="">
                     <div className='h-full grid items-center justify-items-center gap-[20px]'>
                        <CardConferencistas img='home/conferencias/drJaeson.png' name='Dr. JAESON CALLA' text='“Apicomplexan parasites manipulate the host secretion apparatus to favor their development and to hide from the immune system”' nameColor="verdei" textColor="black" />
                        <CardConferencistas img='home/conferencias/drChristian.png' name='Dr. CHRISTIAN IBAÑEZ' text='"La biogeografía como una evidencia de la evolución biológica"' nameColor="verdei" textColor="black" />
                     </div>
                  </CardTitleSimposios>
               </div>
               <div className='lg:!h-full'>
                  <CardTitleSimposios text="JUEVES 10" gradientColors="" bgColor="azuli" textColor="">
                     <div className='h-full grid items-center justify-items-center gap-[20px]'>
                        <CardConferencistas img='home/conferencias/drRicardo.png' name='Dr. RICARDO FUJITA' text='"Paradigmas en genética molecular humana"' nameColor="yellowi" textColor="white" />
                        <CardConferencistas img='home/conferencias/marcelo.png' name='Dr. MARCELO MIRAGAYA' text='"Estado actual de las biotecnologías en equinos"' nameColor="yellowi" textColor="white" />
                        <CardConferencistas img='home/conferencias/drHeinner.png' name='Dr. HEINNER GUIO' text='"Diversidad genética en la medicina de la precisión"' nameColor="yellowi" textColor="white" />
                     </div>
                  </CardTitleSimposios>
               </div>
               <div className='lg:!h-full'>
                  <CardTitleSimposios text="VIERNES 11" gradientColors={['#FFFFFF', '#D3FFD6']} bgColor="" textColor="">
                     <div className='h-full grid items-center justify-items-center gap-[20px]'>
                        <CardConferencistas img='home/conferencias/drMev.png' name='Dra. MEV DOMINGUEZ' text='"Medicina personalizada en cáncer colorectal hereditario"' nameColor="verdei" textColor="black" />
                        <CardConferencistas img='home/conferencias/drMarcelo.png' name='Dr. MARCELO OLIVA' text='"Parásitos como indicadores de procesos oceanográficos: el caso de sistemas de afloramientos y ecosistemas de agua profundas en el pacifico suramericano"' nameColor="verdei" textColor="black" />
                     </div>
                  </CardTitleSimposios>
               </div>
            </div>
         </section>
         <section className='mb-[20px] sm:mb-[40px]'>
            <NavLink to="/"
               className='grid justify-center'>
               <BtnBack />
            </NavLink>
         </section>
      </motion.div>
   )
}
