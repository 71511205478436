import CardConferencias from '../components/cards/CardConferencias'
import { useMediaQuery } from 'react-responsive'
import SwiperConferencias from '../components/swipers/SwiperConferencias'
import CardComité from '../components/cards/CardComité'
import SwiperComite from '../components/swipers/SwiperComite'
import CardConferencias2 from '../components/cards/CardConferencias2'
import { Link, NavLink } from 'react-router-dom'
import BtnConferencias from '../components/buttons/BtnConferencias'
import BtnSimposios from '../components/buttons/BtnSimposios'
import { BtnResumenes } from '../components/buttons/BtnResumenes'
import { motion } from "framer-motion"
import HeroSwiper from '../components/swipers/HeroSwiper'

const Home = () => {
	const isLg = useMediaQuery({ query: '(min-width: 1100px)' })
	const isXl = useMediaQuery({ query: '(min-width: 1280px)' })

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.8 }}
			className="overflow-x-hidden">
			<section id='Hero'>
				<HeroSwiper/>
			</section>

			<section id='Texto'
				className="bg-azuli">
				<div
					className='containerW-1048 pySections'>
					<p
						className='font-Nsans text-center text-white
                  		text-[16px] leading-[32px]
                  		sm:text-[20px] sm:leading-[34px]
                  		lg:text-[22px] lg:leading-[36px]'>
						La Facultad de Ciencias Biológicas de la Universidad Nacional Mayor de San Marcos y su Instituto de Investigación
						de Ciencias Biológicas “Antonio Raimondi” (ICBAR), se complacen en ofrecer a los investigadores y público en general,
						el II Congreso Nacional Ciencias de la vida y la XXVIII Reunión Científica ICBAR entre el 9 y 11 de agosto del 2023.
					</p>
				</div>
			</section>

			<section id='Resumenes'
				className='bg-[url("/public/home/hero/bg-resumenes.jpg")] h-auto bg-cover bg-center relative
        		before:bg-black before:w-full before:h-full before:absolute before:opacity-50'>
				<div
					className='containerW-1048 pySections z-20 relative
          			grid gap-[20px] sm:gap-[32px]'>
					<h3 className='text-white'> Libro de resúmenes ICBAR </h3>
					<NavLink to="libro"
						className='flex justify-center'>
						<BtnResumenes />
					</NavLink>
				</div>
			</section>

			<section id='Conferencias'>
				<div className='pySections'>
					<h2 className="pb-[32px] md:pb-[40px] px-[5%]">Conferencias Magistrales</h2>
					{isXl ?
						<div className='grid gap-[40px]'>
							<div className='grid grid-cols-4 gap-[32px]
                     			max-w-[1184px] mx-auto px-[5%] md:px-[8%] xl:px-0'>
								<CardConferencias name="Dr. Christian Ibañez" img="/home/conferencias/drChristian.png"
									text="Biólogo Marino doctorado en Ecología y Biología Evolutiva en la Universidad de Chile. Es profesor titular de la Facultad de Ciencias de la Vida de la Universidad Andrés Bello. Sus investigaciones se centran en la biodiversidad, ecología y evolución de invertebrados marinos en aspectos biogeográficos y macroevolutivos. " />
								<CardConferencias name="Dr. Marcelo Oliva" img="/home/conferencias/drMarcelo.png"
									text="Biólogo pesquero doctorado en Oceanología Biológica en la Universidad de Bretaña Occidental (Francia). Es profesor titular de la Facultad de Ciencias del Mar y Recursos Biológicos de la Universidad de Antofagasta. Su área de investigación es la parasitología marina enfocada al estudio de sistemas parásito-huésped de peces e invertebrados, en aspectos ecológicos y pesqueros." />
								<CardConferencias name="Dr. Heinner Guio" img="/home/conferencias/drHeinner.png"
									text="Médico doctorado en Ciencias Médicas en la universidad de Tohoku, Japón y realizó su postdoctorado en el Imperial College de Londres, Inglaterra. Sus principales investigaciones se centran en la identificación de nuevas variantes genéticas en poblaciones nativas y mestizas peruanas y búsqueda de marcadores genéticos e inmunológicos en la infección por tuberculosis." />
								<CardConferencias name="Dr. Marcelo Miragaya" img="/home/conferencias/marcelo.png"
									text="Médico Veterinario doctorado en Fisiología Animal en la University of Idaho, USA.  Es profesor titular de la cátedra de Teriogenología de la Facultad de Ciencias Veterinarias de la UBA. Su línea de investigación está aplicada a la reproducción y biotecnologías reproductivas en la especie equina y en camélidos sudamericanos." />
							</div>
							<div className='grid grid-cols-3 gap-[32px]
                     			max-w-[880px] mx-auto px-[5%] md:px-[8%] xl:px-0'>
								<CardConferencias2 name="Dr. Ricardo Fujita" img="/home/conferencias/drRicardo.png"
									text="Biólogo genetista doctorado en Genética Molecular en la Universidad de Strasbourg, Francia y realizó su postdoctorado en la Universidad de Michigan, EEUU. Es Director del Instituto de Investigación de la Facultad de Medicina Humana de la Universidad San Martín de Porres. Sus líneas de investigación incluyen: Enfermedades Raras, Cáncer, Farmacogenética, Inmunogenética, la Historia vista por la Genética, Terapia Génica, Células Madre." />
								<CardConferencias2 name="Dra. Mev Domínguez" img="/home/conferencias/drMev.png"
									text="Bióloga genetista doctorada en Oncología en la Universidad Federal de Sao Paulo, Brasil. Es investigadora del Departamento de Biología Tumoral del Cáncer del Instituto de Investigación en Cáncer del Hospital Universitario de Oslo, Noruega, directora del programa del Grupo Europeo de Tumores Hereditarios y asesora científica del Grupo Latinoamericano de Tumores Hereditarios. Su línea de investigación es en Síndrome de Lynch." />
								<CardConferencias2 name="Dr. Jaeson Calla" img="/home/conferencias/drJaeson.png"
									text="Biólogo doctorado en Centro de Investigación y Estudios Avanzados del Instituto Nacional Politécnico, México. Es científico asistente de proyectos e investigador de la malaria en el Laboratorio de Patogénesis Eucariótica, Descubrimiento de Fármacos y Biología Química de la Facultad de Medicina de la Universidad de California en San Diego. Trabaja en el descubrimiento de compuestos antipalúdicos y la respuesta inmunitaria por Plasmodium durante las infecciones exoeritrocitarias." />
							</div>
						</div>
						:
						<div className='max-w-[1184px] mx-auto pl-[5%] md:pl-[8%] xl:px-0'>
							<SwiperConferencias />
						</div>
					}
					<div
						className='w-full grid pt-[40px] gap-[20px] max-w-[320px] px-[3%]
                  		md:flex md:pt-[28px] md:gap-[24px] md:max-w-[640px] md:px-0 mx-auto'>
						<Link to="conferencias-magistrales"
							className='w-full'>
							<BtnConferencias />
						</Link>
						<Link to="simposios"
							className='w-full'>
							<BtnSimposios />
						</Link>
					</div>
				</div>
			</section>

			<section id='Comité'
				className="bg-azuli">
				<div className='pySections'>
					<h2 className="text-white pb-[32px] lg:pb-[40px] xl:pb-[32px] px-[5%]">
						Comité Organizador
					</h2>
					{isLg ?
						<div className='max-w-[1120px] mx-auto px-[5%] md:px-[8%] auxXl:px-0
                  			flex justify-between gap-[20px] auxXl:gap-[32px] '>
							<CardComité name='MG. ZEZÉ BRAVO' img='/home/comite/zeze.jpg'
								cargo='Presidente' />
							<CardComité name='MG. HENRY PAICO' img='/home/comite/henry.jpg'
								cargo='Secretario de Actas' />
							<CardComité name='MG. CLAUDIA BARLETTA' img='/home/comite/claudia.jpg'
								cargo='Tesorera' />
							<CardComité name='DR. ALDO PACHECO' img='/home/comite/aldo.jpg'
								cargo=' Secretario Científico' />
							<CardComité name='DRA. ROXANA CASTAÑEDA' img='/home/comite/roxana.jpg'
								cargo='Secretaria de difusión' />
						</div>
						:
						<div className='max-w-[1200px] mx-auto px-[5%] md:px-[8%] auxXl:px-0'>
							<SwiperComite />
						</div>

					}
				</div>
			</section>

			<section id='Organizan'>
				<div
					className='max-w-[800px] mx-auto px-[5%] sm:px-[6%] md:px-[8%] lg:px-0 pySections'>
					<h2 className="pb-[32px] lg:pb-[40px]">Organizan</h2>
					<div
						className='grid items-center gap-[32px]
                  		md:flex md:justify-between'>
						<img src="/home/organizan/logoSm.png" alt="GenLab"
							className='md:w-[240px] lg:w-auto mx-auto' />
						<img src="/home/organizan/logoFac.png" alt="GenLab"
							className='md:w-[260px] lg:w-auto mx-auto' />
						<img src="/home/organizan/logoICBAR.png" alt="GenLab"
							className='md:w-[89px] lg:w-auto mx-auto' />
					</div>
				</div>
			</section>

			<section id='Auspiciadores'>
				<div
					className='max-w-[920px] mx-auto px-[6%] sm:px-[6%] md:px-[8%] lg:px-0 pySections mb-[8px]'>
					<h2 className="pb-[32px] md:pb-[40px]">Auspiciadores</h2>
					<div
						className='grid items-center justify-center
                  		gap-y-[32px] sm:gap-y-[42px]'>
						<div
							className='grid items-center justify-center
                     		grid-cols-2 gap-x-[48px] gap-y-[32px] sm:gap-y-[80px]
                     		sm:grid-cols-3 sm:gap-x-[48px] xl:gap-x-[60px] 
                     		md:grid-cols-4 lg:grid-cols-6'>
							<img src="/home/auspiciadores/logoConcytec.png" alt="Concytec"
								className='w-auto mx-auto lg:scale-110' />
							<img src="/home/auspiciadores/logoProciencia.png" alt="ProCiencia"
								className='w-auto mx-auto lg:scale-110' />
							<img src="/home/auspiciadores/logoMaskaf.png" alt="Maskaf"
								className='w-auto mx-auto lg:scale-110' />
							<img src="/home/auspiciadores/logoMased.png" alt="Mased"
								className='w-auto mx-auto lg:scale-110' />
							<img src="/home/auspiciadores/logoMimarqui.png" alt="Mimarqui"
								className='w-auto mx-auto lg:scale-110' />
							<img src="/home/auspiciadores/logoGenLab.png" alt="GenLab"
								className='w-auto mx-auto lg:scale-110' />
							<img src="/home/auspiciadores/logoCiclos.png" alt="CiclosStudio"
								className='w-auto mx-auto lg:scale-110' />
							<img src="/home/auspiciadores/logoTraduPharma.png" alt="TraduPharma"
								className='w-auto mx-auto lg:scale-110' />
							<img src="/home/auspiciadores/logoI3d.png" alt="I3d"
								className='w-auto mx-auto lg:scale-110' />
							<img src="/home/auspiciadores/logoNatGen.png" alt="NatGen"
								className='w-auto mx-auto lg:scale-110' />
							<img src="/home/auspiciadores/logoDscience.png" alt="Dscience"
								className='w-auto mx-auto lg:scale-110' />
							<img src="/home/auspiciadores/logoGrupoGrat.png" alt="GrupoGrat"
								className='w-auto mx-auto lg:scale-110' />
						</div>
					</div>
				</div>
			</section>
		</motion.div>
	)
}

export default Home
