import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";
import CardComité from "../cards/CardComité";
export default function SwiperComite() {
   return (
      <>
         <Swiper
            style={{
               "--swiper-pagination-color": "#969696",
               "--swiper-pagination-bullet-inactive-color": "#D9D9D9",
               "--swiper-pagination-bullet-inactive-opacity": "1",
            }}
            slidesPerView={3}
            slidesPerGroup={1}
            spaceBetween={32}
            pagination={{
               clickable: true,
            }}
            breakpoints={{

               1000: {
                  slidesPerView: 3.5,
               },
               800: {
                  slidesPerView: 3,
               },
               640: {
                  slidesPerView: 2.5,
               },
               540: {
                  slidesPerView: 2,
               },
               420: {
                  slidesPerView: 1.8,
               },
               360: {
                  slidesPerView: 1.6,
               },
               100: {
                  slidesPerView: 1,
               },
            }}
            modules={[Pagination]}
            className="mySwiper"
         >
            <SwiperSlide>
               <CardComité name='MG. ZEZÉ BRAVO' img='/home/comite/zeze.jpg'
                  cargo='Presidente' />
            </SwiperSlide>
            <SwiperSlide>
               <CardComité name='MG. HENRY PAICO' img='/home/comite/henry.jpg'
                  cargo='Secretario de Actas' />
            </SwiperSlide>
            <SwiperSlide>
               <CardComité name='MG. CLAUDIA BARLETTA' img='/home/comite/claudia.jpg'
                  cargo='Tesorera' />
            </SwiperSlide>
            <SwiperSlide>
               <CardComité name='DR. ALDO PACHECO' img='/home/comite/aldo.jpg'
                  cargo=' Secretario Científico' />
            </SwiperSlide>
            <SwiperSlide>
               <CardComité name='DRA. ROXANA CASTAÑEDA' img='/home/comite/roxana.jpg'
                  cargo='Secretaria de difusión' />
            </SwiperSlide>
         </Swiper>
      </>
   );
}
