import React from 'react'
import SectionVideosOpen from '../../components/sections/SectionVideosOpen'
import CardVideos from '../../components/cards/CardVideos'
import SectionVideos from '../../components/sections/SectionVideos'

const Simposios = () => {
  const category = "Simposios"
  return (
    <div>
      <SectionVideosOpen
        category={category}
        title={'"Biotecnologías reproductivas aplicadas en reproducción asistida"'}
        video1="https://www.youtube.com/embed/1mml3sPHoZo?list=PLF2wTKoArikOnJUT27_UN-up8Pmzc9wOV"
        video2="https://www.youtube.com/embed/KL90cGTZHIQ"
        video3="https://www.youtube.com/embed/bJnscjxc-CE"
        video4="https://www.youtube.com/embed/xThDFQosjE8">
        <CardVideos link={"https://www.youtube.com/embed/5CbTiEJC_2Y"} />
        <CardVideos link={"https://www.youtube.com/embed/RRgjpc0zmhQ"} />
        <CardVideos link={"https://www.youtube.com/embed/EUyTfJLqDZk"} />
      </SectionVideosOpen>
      <SectionVideosOpen
        category={category}
        title={'"Aplicaciones de la biotecnología para la generación de anticuerpos contra el veneno animal"'}
        video1="https://www.youtube.com/embed/HeHcumkbij8"
        video2="https://www.youtube.com/embed/0HrMwSpXKTc"
        video3="https://www.youtube.com/embed/Tr65KlOxFN8"
        video4="https://www.youtube.com/embed/7FAtdmwOndY">
        <CardVideos link={"https://www.youtube.com/embed/zJ1moZ0Caeo"} />
        <CardVideos link={"https://www.youtube.com/embed/r0cGPtBBeWA"} />
        <CardVideos link={"https://www.youtube.com/embed/1N41ShKjuR0"} />
      </SectionVideosOpen>
      <SectionVideos
        category={category}
        title={'“Genética y genómica del cáncer”'}>
        <CardVideos link={"https://www.youtube.com/embed/4xKx7jogWyY"} />
        <CardVideos link={"https://www.youtube.com/embed/fBbUcbQYU74"} />
        <CardVideos link={"https://www.youtube.com/embed/6wyV-Vk2V9I"} />
      </SectionVideos>
      <SectionVideosOpen
        category={category}
        title={'"Inmunología del cáncer"'}
        video1="https://www.youtube.com/embed/cr2KlcTyLmQ?list=PLF2wTKoArikOnJUT27_UN-up8Pmzc9wOV"
        video2="https://www.youtube.com/embed/FgPkbz5MgHk?list=PLF2wTKoArikOnJUT27_UN-up8Pmzc9wOV"
        video3="https://www.youtube.com/embed/asy-LwBkjpU?list=PLF2wTKoArikOnJUT27_UN-up8Pmzc9wOV"
        video4="https://www.youtube.com/embed/iKqNKtpTD7I">
        <CardVideos link={"https://www.youtube.com/embed/yb0gcEHeJkM"} />
        <CardVideos link={"https://www.youtube.com/embed/F1JWLqrjJJ8"} />
        <CardVideos link={"https://www.youtube.com/embed/4a7Lu4JmxBA"} />
        <CardVideos link={"https://www.youtube.com/embed/YLtUMK4mLlo"} />
      </SectionVideosOpen>
      <SectionVideos
        category={category}
        title={'“Actualidad de la investigación y aplicación de bacteriogafo en el Perú”'}>
        <CardVideos link={"https://www.youtube.com/embed/qxN5bHHHMT0?list=PLF2wTKoArikOnJUT27_UN-up8Pmzc9wOV"} />
        <CardVideos link={"https://www.youtube.com/embed/k8EpQCm7_kE?list=PLF2wTKoArikOnJUT27_UN-up8Pmzc9wOV"} />
        <CardVideos link={"https://www.youtube.com/embed/yD4Mo3bWG84"} />
        <CardVideos link={"https://www.youtube.com/embed/bmmLLj8q0L0"} />
      </SectionVideos>
      <SectionVideosOpen
        category={category}
        title={'"Macroinvertebrados acuáticos y su importancias como bioindicadores de la calidad del agua"'}
        video1="https://www.youtube.com/embed/LOJBMGN0t4Q?list=PLF2wTKoArikOnJUT27_UN-up8Pmzc9wOV"
        video2="https://www.youtube.com/embed/oF5VzUJLIuc?list=PLF2wTKoArikOnJUT27_UN-up8Pmzc9wOV"
        video3="https://www.youtube.com/embed/cHq2ly21xqI"
        video4="https://www.youtube.com/embed/kqUI1eOiNhI">
        <CardVideos link={"https://www.youtube.com/embed/__i8IkYzMvk"} />
        <CardVideos link={"https://www.youtube.com/embed/NbowXq8Bwy0"} />
        <CardVideos link={"https://www.youtube.com/embed/LOJBMGN0t4Q"} />
        <CardVideos link={"https://www.youtube.com/embed/oF5VzUJLIuc"} />
      </SectionVideosOpen>
      <SectionVideosOpen
        category={category}
        title={'"Series de tiempo y los beneficios del monitoreo de biodiversidad de largo plazo"'}
        video1="https://www.youtube.com/embed/IDO5AyHPIkE"
        video2="https://www.youtube.com/embed/kCPEN_bqNng"
        video3="https://www.youtube.com/embed/2CQHqMnR3KI"
        video4="https://www.youtube.com/embed/sMOyAyCTWu4" >
        <CardVideos link={"https://www.youtube.com/embed/FgUDDtLnpVg"} />
        <CardVideos link={"https://www.youtube.com/embed/V8O0j4HhNJI"} />
        <CardVideos link={"https://www.youtube.com/embed/qURXlD7d6rE"} />
        <CardVideos link={"https://www.youtube.com/embed/lgT2LHHrBtE"} />
        <CardVideos link={"https://www.youtube.com/embed/WFqJCos0Rm8"} />
      </SectionVideosOpen>
      <SectionVideosOpen
        category={category}
        title={'"Experimentos y aplicación de tecnologías para la conservación de la biodiversidad"'}
        video1="https://www.youtube.com/embed/3iJRplawL1M"
        video2="https://www.youtube.com/embed/dGworKqS4hk"
        video3="https://www.youtube.com/embed/LdQ0HwoF87M"
        video4="https://www.youtube.com/embed/H7yCWTJp7Hw" >
        <CardVideos link={"https://www.youtube.com/embed/2b_Do7hq-2I"} />
        <CardVideos link={"https://www.youtube.com/embed/xn2Ha8t1hZc"} />
        <CardVideos link={"https://www.youtube.com/embed/SEsM8XgmuV8"} />
        <CardVideos link={"https://www.youtube.com/embed/DPUKpxL0wb8"} />
      </SectionVideosOpen>
    </div>
  )
}

export default Simposios
