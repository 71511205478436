import React from 'react';
import { motion } from 'framer-motion';

const CardVideos = ({ link, title, name }) => {

  return (
    <motion.div
      initial={{ opacity: 0, x: 0 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 100 }}
      transition={{ duration: 1.5 }}
      className='relative'
    >
      <iframe
        src={link}
        title={title}
        className='w-full !h-full min-h-[200px] sm:min-h-[318px] lg:min-h-[332px]'
      ></iframe>
    </motion.div>
  );
};

export default CardVideos;
