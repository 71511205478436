import React from 'react'

export const BtnResumenes = () => {
    return (
        <button
            className='bg-yellowi border-[2px]  border-yellowi rounded-[8px]
            flex items-center text-black h-[64px] px-[16px] sm:px-[24px]
            hover:bg-transparent hover:text-yellowi'>
            <p className='font-[Inter] font-bold !text-center
                text-[18px] sm:text-[24px]'>
                Descarga el libro
            </p>
        </button>
    )
}
