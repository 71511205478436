import React from 'react'
import SectionVideos from '../../components/sections/SectionVideos'
import SectionVideosOpen from '../../components/sections/SectionVideosOpen'
import CardVideos from '../../components/cards/CardVideos'

const Simposios = () => {
  const category = "Simposios"
  return (
    <div>
      <SectionVideosOpen
        category={category}
        title={'"Ecología de ecosistemas bentónicos"'}
        video1="https://www.youtube.com/embed/tdFX1yxtHQ8?list=PLF2wTKoArikO2J7lyuON9fiGbRhlJABLk"
        video2="https://www.youtube.com/embed/GNJXF8_2QDE?list=PLF2wTKoArikO2J7lyuON9fiGbRhlJABLk"
        video3="https://www.youtube.com/embed/g3r_J5wZvuc?list=PLF2wTKoArikO2J7lyuON9fiGbRhlJABLk"
        video4="https://www.youtube.com/embed/HclPGAOhBIU?list=PLF2wTKoArikO2J7lyuON9fiGbRhlJABLk">
        <CardVideos link={"https://www.youtube.com/embed/ipAU7MyCP6s?list=PLF2wTKoArikO2J7lyuON9fiGbRhlJABLk"} />
        <CardVideos link={"https://www.youtube.com/embed/DBNMPqtK-7g?list=PLF2wTKoArikO2J7lyuON9fiGbRhlJABLk"} />
        <CardVideos link={"https://www.youtube.com/embed/yknXhdxjsEU?list=PLF2wTKoArikO2J7lyuON9fiGbRhlJABLk"} />
      </SectionVideosOpen>
      <SectionVideosOpen
        category={category}
        title={'"Ecología de reptiles"'}
        video1="https://www.youtube.com/embed/k_oTp96mxJk"
        video2="https://www.youtube.com/embed/KukPnV6TOXU"
        video3="https://www.youtube.com/embed/dI54a1anpBU"
        video4="https://www.youtube.com/embed/K4setmgJwAA">
        <CardVideos link={"https://www.youtube.com/embed/VaJrKToew18"} />
        <CardVideos link={"https://www.youtube.com/embed/LlWTydmUSak"} />
        <CardVideos link={"https://www.youtube.com/embed/A4h3pAW5tvY"} />
      </SectionVideosOpen>
      <SectionVideosOpen
        category={category}
        title={'"Dilemas y desafios en el conocimineto del genero cinchoma"'}
        video1="https://www.youtube.com/embed/g4iITWI1n8E"
        video2="https://www.youtube.com/embed/PL3yzBagYn4"
        video3="https://www.youtube.com/embed/A59i4XS8VjE"
        video4="https://www.youtube.com/embed/2JiKfcWaIL4">
        <CardVideos link={"https://www.youtube.com/embed/Yoq8ScFpIjY"} />
        <CardVideos link={"https://www.youtube.com/embed/IY1z3YuX5jo"} />
        <CardVideos link={"https://www.youtube.com/embed/HKhIXCDGcSk"} />
        <CardVideos link={"https://www.youtube.com/embed/EqYYP0_8Pkg"} />
        <CardVideos link={"https://www.youtube.com/embed/VP87Yj0Doww"} />
      </SectionVideosOpen>
      <SectionVideos
        category={category}
        title={'"Reproduccion y biologia del desarrollo preimplantacional del embrion"'}>
        <CardVideos link={"https://www.youtube.com/embed/i4peFhxoukY?list=PLF2wTKoArikO2J7lyuON9fiGbRhlJABLk"} />
        <CardVideos link={"https://www.youtube.com/embed/Y1Bv9T4BMQw"} />
        <CardVideos link={"https://www.youtube.com/embed/PSZGCP_WLk8"} />
      </SectionVideos>
      <SectionVideos
        category={category}
        title={'"Genomica evolutiva."'}>
        <CardVideos link={"https://www.youtube.com/embed/Yp9bQSp6Mtk"} />
        <CardVideos link={"https://www.youtube.com/embed/xeSf0rGENvg"} />
        <CardVideos link={"https://www.youtube.com/embed/cYvbufF9Q88"} />
        <CardVideos link={"https://www.youtube.com/embed/EPcAFehnFO8"} />
      </SectionVideos>
      <SectionVideos
        category={category}
        title={'"La odisea diagnostica de las enfermedades raras"'}>
        <CardVideos link={"https://www.youtube.com/embed/FkxFreBOEKo"} />
        <CardVideos link={"https://www.youtube.com/embed/aIomNtxBWEs"} />
        <CardVideos link={"https://www.youtube.com/embed/CREY5fV6dXY"} />
      </SectionVideos>
    </div>
  )
}

export default Simposios
