import React from 'react'
import BigImage from '../components/cards/BigImage'
import NormalImage from '../components/cards/NormalImage'
import { motion } from "framer-motion"

const Day2 = () => {
   return (
      <motion.section
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{ duration: 0.8 }}
         className='gridGalery'>
         <BigImage img='galery/day2/img-1.jpg' />
         <NormalImage img='galery/day2/img-2.jpg' />
         <NormalImage img='galery/day2/img-3.jpg' />
         <NormalImage img='galery/day2/img-4.jpg' />
         <NormalImage img='galery/day2/img-5.jpg' />
         <NormalImage img='galery/day2/img-6.jpg' />
         <NormalImage img='galery/day2/img-7.jpg' />
         <BigImage img='galery/day2/img-8.jpg' />
         <NormalImage img='galery/day2/img-9.jpg' />
         <NormalImage img='galery/day2/img-10.jpg' />
         <NormalImage img='galery/day2/img-11.jpg' />
         <NormalImage img='galery/day2/img-12.jpg' />
         <BigImage img='galery/day2/img-13.jpg' />
         <NormalImage img='galery/day2/img-14.jpg' />
         <NormalImage img='galery/day2/img-15.jpg' />
      </motion.section>
   )
}

export default Day2