import React from 'react'
import NormalImage from '../components/cards/NormalImage'
import { motion } from "framer-motion"
import BigImage from '../components/cards/BigImage'

const Day3 = () => {
    return (
        <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className='gridGalery'>
            <BigImage img='galery/day3/img-1.jpg' />
            <NormalImage img='galery/day3/img-2.jpg' />
            <NormalImage img='galery/day3/img-3.jpg' />
            <NormalImage img='galery/day3/img-4.jpg' />
            <NormalImage img='galery/day3/img-5.jpg' />
            <NormalImage img='galery/day3/img-6.jpg' />
            <NormalImage img='galery/day3/img-7.jpg' />
            <BigImage img='galery/day3/img-8.jpg' />
            <NormalImage img='galery/day3/img-9.jpg' />
            <NormalImage img='galery/day3/img-10.jpg' />
            <NormalImage img='galery/day3/img-11.jpg' />
            <NormalImage img='galery/day3/img-12.jpg' />
            <BigImage img='galery/day3/img-13.jpg' />
            <NormalImage img='galery/day3/img-14.jpg' />
            <NormalImage img='galery/day3/img-15.jpg' />
            <NormalImage img='galery/day3/img-16.jpg' />
            <NormalImage img='galery/day3/img-17.jpg' />
            <NormalImage img='galery/day3/img-18.jpg' />
            <NormalImage img='galery/day3/img-19.jpg' />
            <BigImage img='galery/day3/img-20.jpg' />
            <NormalImage img='galery/day3/img-21.jpg' />
            <NormalImage img='galery/day3/img-22.jpg' />
            <NormalImage img='galery/day3/img-23.jpg' />
            <NormalImage img='galery/day3/img-24.jpg' />
            <BigImage img='galery/day3/img-25.jpg' />
            <NormalImage img='galery/day3/img-26.jpg' />
            <NormalImage img='galery/day3/img-27.jpg' />
            <NormalImage img='galery/day3/img-28.jpg' />
            <NormalImage img='galery/day3/img-29.jpg' />
            <NormalImage img='galery/day3/img-30.jpg' />
        </motion.section>
    )
}

export default Day3