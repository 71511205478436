import React from 'react'

const Footer = () => {
    return (
        <footer
            className="bg-azuli !w-full flex justify-self-end justify-items-center justify-center">
            <div className='py-[12px] sm:py-[28px] px-[8%] sm:px-[4%]'>
                <p
                    className='text-white text-center
                    font-Nsans text-[12px] sm:text-[16px]'>
                    Todos los derechos reservados © UNMSM 2023 | Design by {" "}
                    <span className='font-bold'>
                        <a href='https://ciclos.studio/' 
                            rel="noreferrer"
                            target="_blank"
                            className='cursor-pointer text-[#EBC235] hover:text-white'>
                            CICLOS STUDIO
                        </a>
                    </span>
                </p>
            </div>
        </footer>
    )
}

export default Footer
