import React from 'react'

const CardComité = ({ name, img, cargo }) => {
    return (
        <div
            className='text-white text-center
            w-[200px] mx-auto mdAux:w-full
            pt-[24px]
            mb-[50px] md:mb-[72px] auxXl:mb-0'>
            <img alt={name}
                src={img}
                className='rounded-full
                mx-auto object-cover w-[140px] h-[140px]' />
            <p className='font-[Jost] font-semibold
                pt-[16px] text-[14px]'>
                {name}
            </p>
            <p className='font-Nsans text-[16px] md:text-[14px]'>
                {cargo}
            </p>
        </div>
    )
}

export default CardComité