import React from 'react'

const CardConferencias = ({ name, text, img }) => {
    return (
        <div
            className='w-[243px] h-[560px] sm:h-[596px] sm:w-[272px] xl:h-full xl:w-full mx-auto bg-white
            py-[24px] sm:py-[16px] px-[16px] mb-[50px] md:mb-[72px] xl:mb-0
            border-[1px] border-[#CACACA] rounded-[8px]'>
            <img alt={name} src={img}
                className='mx-auto' />
            <p className='text-center text-azuli
                font-[Jost] font-semibold 
                text-[20px] py-[16px] sm:text-[24px] sm:pb-[8px]'>
                {name}
            </p>
            <p className='text-center font-Nsans
                text-[14px]  leading-[20px]
                sm:text-[16px] sm:leading-[24px]'>
                {text}
            </p>
        </div>
    )
}

export default CardConferencias
